import 'leaflet/dist/leaflet.css';

import React from 'react';

import Routes from './routes';

import { ToastProvider } from 'react-toast-notifications';

import "./App.css";

function App() {
  return (
    <>
      <ToastProvider placement={'top-right'}>
        <Routes />
      </ToastProvider>
    </>
  );
}

export default App;
