import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import Leaflet from "leaflet";

import { css } from "@emotion/core";

import Loader from "react-spinners/BarLoader";

import { fetchLocalMapBox } from "../../apiMapBox";

import AsyncSelect from "react-select/async";

import api from '../../services/api';

//import mapPin from "../../assets/pin2.svg";

import mapPin from "../../assets/logo.png";
import { useToasts } from 'react-toast-notifications';

import ReactTooltip from 'react-tooltip';

import Collapse from "@kunukn/react-collapse";

import { BsArrowBarDown } from 'react-icons/bs';
import { BsArrowBarUp } from 'react-icons/bs';

const initialPosition = {lat: -19.8157, lng: -43.9542};

const mapPinIcon = Leaflet.icon({
  iconUrl: mapPin,
  iconSize: [100, 50],
  iconAnchor: [29, 68],
  popupAnchor: [0, 130],
  customId: ''
});

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  margin-top: 20px;
`;


function Home() {
    const [address, setAddress] = useState('')
    const [loading, setLoading] = useState(false);
    const [toggle, setToggle] = useState(true);
    const [markers, setMarkers] = useState({});

    const [companies, setCompanies] = useState([]);

    const [startLocation, setStartLocation] = useState(initialPosition);
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);

    const [ready, setReady] = useState(false);
    const [zoom, setZoom] = useState(15);

    const [map, setMap] = useState(null);

    const { addToast } = useToasts();

    function showToast(text) {
      addToast(text, {
          appearance: 'info',
          autoDismiss: true,
      });
    }

    function createButton(label, container) {
      var btn = Leaflet.DomUtil.create('button', '', container);
      btn.setAttribute('type', 'button');
      btn.innerHTML = '';
      return btn;
      }

    const handleChangeSelect = (event) => {
        setAddress({ label: event.place, value: event.place });

        setLatitude(event.coords[1])
        setLongitude(event.coords[0])
    }


    const getCompanies = async () => { 
      var container = Leaflet.DomUtil.create('div');

      if(latitude && longitude) {
        await api.get(`companies/?lat=${latitude}&long=${longitude}`).then(response => {
          response.data.companies.forEach((company) => {
            var id = company.id;
            var div = Leaflet.DomUtil.create('div');
            div.innerHTML = `<div><h3>`+ company.name +`</h3><p>`+company.address+` - `+company.city+`</p><span class="how-to-span"><button class="how-to-button" onclick="window.open('https://maps.google.com/maps?daddr=`+company.latitude+`,`+company.longitude+`&amp;ll=');" type="button"> Como Chegar </button> </span></div>`
            var custom = {
                'maxWidth': '200',
                'minWidth': '200',
                'className': 'map-popup',
                'closeButton': false,
            }
            var latLng = Leaflet.latLng([company.latitude, company.longitude])
            markers[id] = new Leaflet.marker(latLng, {id: id, icon: mapPinIcon})
            .bindPopup(div, custom).addTo(map)
          });
          
          setCompanies(response.data.companies)
          setReady(true);
          setLoading(false)
        });
      }
    }

    async function handleSubmit(event) {

      event.preventDefault();
      if(latitude && longitude){
        setLoading(true)
        var latlng = Leaflet.latLng(latitude, longitude);
        map.flyTo(latlng, 15)
        getCompanies();
        
      } else {
        showToast('Digite seu endereço!');
      }
    }

    const handleCardClick = (company) => {
      var latlng = Leaflet.latLng(company.latitude, company.longitude);
      map.flyTo(latlng, 15)
      setToggle(false)
      markers[company.id].openPopup();
    }

    const handleToggle = () => {
      ReactTooltip.show()    
      setToggle(toggle => !toggle)
      ReactTooltip.hide()    
    }

    const loadOptions = async(inputValue, callback) => {
      if (inputValue.length < 5) return;
        let places= [];
        const response = await fetchLocalMapBox(inputValue);
        response.features.map((item) => {
        places.push({
          label: item.place_name,
          value: item.place_name,
          coords: item.center,
          place: item.place_name,
        });
     });
     callback(places);
    }

    return (
        <div id="page-map">
          <main>
          <form  onSubmit={handleSubmit} className="landing-page-form">
            <header>                 
              <div className="form-header">Procurando produtos Massalho?</div>
              <div className="form-icon" data-tip={!toggle ? 'Clique aqui para mostrar o menu':'Clique aqui para esconder o menu'}>

                { toggle ?
                  <BsArrowBarUp  onClick={handleToggle} size={28}  />
                  :
                  <BsArrowBarDown onClick={handleToggle} size={28} />
              }
              </div>
            </header>
            <Collapse isOpen={toggle}>
              <fieldset>
                <div className="input-block">
                  <label htmlFor="address">Digite seu endereço:</label>
                  <AsyncSelect
                    placeholder="Digite seu endereço..."
                    classNamePrefix="filter"
                    loadOptions={loadOptions}
                    onChange={handleChangeSelect}
                    value={address}
                    menuPosition={'fixed'}
                    noOptionsMessage={()=> "Digite seu endereço..."}
                  />
                </div>
              </fieldset>
    
              <button className="confirm-button" type="submit">
                Pesquisar
              </button>
              { loading &&
              <Loader color={"#c90738"} loading={loading} css={override} size={32} />
              }
              <div className="company-list" style={companies.length ? {display: 'block'}:{display: 'none'}}>
<div className="company-counter">{companies.length} resultados encontrados!</div>
    {companies.map((company) => {
  return(
    <div key={company.id} onClick={() => handleCardClick(company)} style={{cursor: 'pointer'}} className="card">
      <h3>{company.name}</h3>
      <p>{company.address} - {company.city} </p>
      <div className="card-footer">

        </div>
    </div>
    )
  })}
              </div>
              </Collapse>
            </form>
          </main>
          <MapContainer
center={startLocation}
zoom={zoom}
style={{ width: "100%", height: "100%" }}
whenCreated={setMap}
>

<TileLayer
  url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=${process.env.REACT_APP_ACCESS_TOKEN_MAP_BOX}`}
/>

</MapContainer>
<ReactTooltip place="right" effect="solid"  delayShow={300}  delayHide={200} />
        </div>
      );
}

export default Home;